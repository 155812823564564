
import { Vue, Component, Ref } from 'vue-property-decorator';
import authModule from '../../store/modules/auth';
import appConfigModule from '../../store/plugins/appConfig';
@Component
export default class Login extends Vue {
  @Ref('form') form!: any;
  appConfig: any = appConfigModule;
  $message: any;
  loading: boolean = false;
  model: any = {
    email: null,
    userName: null,
    password: null,
    privacyPolicy: false,
  };

  fields = [
    {
      editor: 'title',
      attrs: {
        title: 'Войти в систему',
        class: 'text-center',
      },
    },
    {
      editor: 'string',
      attrs: {
        label: 'Почта',
        placeholder: 'Например info@mail.ru',
        type: 'email',
      },
      name: 'email',
      validations: [
        {
          validator: 'required',
          errorMessage: 'поле не может быть пустым',
        },
        {
          validator: 'regular',
          value: 'email',
          errorMessage: 'Введите корректный email',
        },
      ],
    },
    {
      editor: 'password',
      attrs: {
        label: 'Пароль',
        hint: 'Введите минимум 8 символов',
        class: 'mb-4',
      },
      name: 'password',
      validations: [
        {
          validator: 'required',
          errorMessage: 'поле не может быть пустым',
        },
        {
          validator: 'min',
          value: 7,
          errorMessage: 'Введите минимум 8 символов',
        },
      ],
    },
  ];

  async login() {
    const valid = await this.form.validate();
    if (!valid) {
      this.$message('Пожалуйста зполните форму правильно!', 'error');
      return;
    }

    this.model.userName = this.model.email;

    try {
      await authModule.signIn(this.model);
      this.$router.push('/');
      this.$message('Добро пожаловать!');
    } catch (error) {
      this.$message(error, 'error');
    }
  }
}
